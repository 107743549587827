import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { T } from '../../typo'
import { s, globals } from '../../style'
import Project from '../../portfolio/Projects/Project'

const Services = ({ theme, pageData }) => {
  const data = useStaticQuery(graphql`
    {
      services: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/services/" } }
        sort: { fields: frontmatter___date, order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              date
              language
              title
              description
              service_slug
              seo_title
              seo_desc
              image {
                name
                childImageSharp {
                  fluid(
                    maxWidth: 1280
                    quality: 100
                    toFormat: JPG
                    jpegProgressive: true
                  ) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const services = data.services.edges.map(({ node }) => node.frontmatter)

  return (
    <div css={sContainer}>
      <div css={sIntro}>
        <T
          d={64}
          m={48}
          bold
          variant="h1"
          extraCss={{
            marginBottom: '2rem',
          }}>
          {pageData.title}
        </T>
        <T>{pageData.description}</T>
      </div>
      <div css={sServices}>
        {services
          // .filter(({ category }) => category === categoryName)
          .map((service, id) => (
            <Project
              key={id}
              id={id}
              data={{ ...service, project_slug: service.service_slug }}
              theme={theme}
            />
          ))}
      </div>
    </div>
  )
}

const sContainer = {
  margin: '0 auto',
  maxWidth: globals.maxWidth,
  [s.lg_1280]: {
    padding: '0 2rem',
  },
  marginBottom: '4rem',
  [s.md]: {
    padding: '0 2rem',
  },
  [s.sm_down]: {
    padding: '0 1.5rem',
  },
}

const sIntro = {
  marginBottom: '8rem',
  paddingTop: '2rem',
  [s.md]: { paddingTop: '2rem' },
  [s.sm_down]: { textAlign: 'center', marginBottom: '4rem' },
}

const sServices = {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: '2rem',
  [s.md_down]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [s.sm_down]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
    gap: '1rem',
  },
}

export default Services
